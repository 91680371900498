import React, { useEffect, useRef } from 'react';
import Player from '@vimeo/player';

function Vimeo({ data }) {
  const playerRef = useRef(null);

  useEffect(() => {
    if (data.vimeo_url !== '') {
      const videoId = getVimeoVideoId(data.vimeo_url);
      const playerWidth = window.innerWidth <= 768 ? 400 : 800;
      // Initialize the Vimeo player once the component mounts
      const player = new Player(playerRef.current, {
        id: videoId,
        byline: false,
        portrait: false,
        title: false,
        width: playerWidth,
        loop: true.valueOf,
      });

      return () => {
        // Clean up the player when the component unmounts
        player.destroy();
      };
    }
  }, [data.vimeo_url]);

  const getVimeoVideoId = (url) => {
    const regex = /https:\/\/player.vimeo.com\/video\/(\d+)/;
    const match = url.match(regex);
    if (match && match.length > 1) {
      return match[1];
    }
    return null;
  };

  const handleResize = () => {
    const containerWidth = playerRef.current.parentNode.offsetWidth;
    const player = playerRef.current;
    player.width = containerWidth;
    player.height = (containerWidth / 16) * 9;
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div
      className="video-container"
      style={{ position: 'relative', width: '100%' }}
    >
      <div
        ref={playerRef}
        className="vimeo-response"
        style={{
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }}
      ></div>
    </div>
  );
}

export default Vimeo;
