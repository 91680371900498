import Zoom from 'react-reveal/Zoom';
import YouTube from 'react-youtube';

function Video({ data }) {
  return (
    <Zoom>
      {data.video_url !== '' && (
        <YouTube
          videoId={data.video_url}
          className="g-flex p-4 mb-4 justify-content-center"
        />
      )}
    </Zoom>
  );
}

export default Video;
