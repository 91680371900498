import Fade from 'react-reveal/Fade';

function Testimonial({ data }) {
  const { testimonial, author, onGrayBackground } = data;

  return (
    <Fade>
      <div
        className={`block--testimonial${
          !onGrayBackground
            ? ' container generic-padding-top generic-padding-bottom'
            : '  container gray-bg'
        }`}
      >
        <div className={`${onGrayBackground ? 'container' : ''}`}>
          <div className="row w-8 justify-content-center align-items-center d-flex mx-auto text-large testimonial-width">
            <div className="col">
              <p
                className="mt-5 text-center"
                dangerouslySetInnerHTML={{ __html: testimonial }}
              />
              <p className="mt-5 mb-5 text-center h6">{author}</p>
            </div>
          </div>
        </div>
      </div>
    </Fade>
  );
}

export default Testimonial;
