import { useEffect, useState } from 'react';
import GraphQlHandler from 'api/craft/GraphQlHandler';
import Footer from 'components/Footer';
import HeroImage from 'components/HeroImage';
import WideText from 'components/WideText';
import ThumbnailsList from 'components/ThumbnailsList';
import TwoColumns from '../components/TwoColumns';
import Fade from 'react-reveal/Fade';

function AboutUs() {
  const [data, setData] = useState({});
  useEffect(() => {
    GraphQlHandler.query(
      `
		query MyQuery {
			aboutUsEntries {
			  ... on aboutUs_aboutUs_Entry {
				id
				title
				header
				heroImage {
				  url
				}
				mainContent
				contentBlock {
					... on contentBlock_headerAndText_BlockType {
						text
						typeHandle
					}
					... on contentBlock_twoColumns_BlockType {
						leftHeader
						leftText
						rightText
						rightHeader
						typeHandle
					}
					... on contentBlock_letsChatButton_BlockType {
						pageUrl
						typeHandle
					}
					... on contentBlock_caseStudy_BlockType {
						typeHandle
						caseStudyBackgroundImage {
							filename
						}
					}
					... on contentBlock_logos_BlockType {
						logo {
							url
							title
						}
						onGrayBackground
						typeHandle
					}
					... on contentBlock_wideImage_BlockType {
						wideImage {
							url
						}
						onGrayBackground
						typeHandle
					}
					... on contentBlock_horizontalDivider_BlockType {
						id
						typeHandle
					}
					... on contentBlock_wideText_BlockType {
						wideText
						onGrayBackground
						typeHandle
					}
					... on contentBlock_testimonial_BlockType {
						id
						onGrayBackground
						testimonial
						author
					}
				}
			  }
			}
		  }
				`
    ).then((res) => {
      const r = res.aboutUsEntries[0];
      setData({
        ...data,
        title: r.title,
        header: r.header,
        mainContent: r.mainContent,
        contentBlock: r.contentBlock,
      });
      document.title = r.title;
    });
  }, []);

  return (
    <>
      <div className="top-gradient"></div>
      <div className="container generic-padding-top generic-padding-bottom p-4">
        <Fade left>
          <div className="container">
            <div className="row generic-padding-top">
              <div className="col-md-6">
                <h1 className="subheads">{data.header}</h1>
                <div dangerouslySetInnerHTML={{ __html: data.mainContent }} />
              </div>
            </div>
          </div>
        </Fade>
      </div>

      {data.contentBlock &&
        data.contentBlock.map((el, key) => {
          switch (el.typeHandle) {
            case 'logos':
              return <ThumbnailsList data={el} key={key} />;
            case 'wideImage':
              return (
                <HeroImage
                  data={{
                    url: el.wideImage[0].url,
                    onGrayBackground: el.onGrayBackground,
                  }}
                  key={key}
                />
              );
            case 'wideText':
              return <WideText data={el} key={key} />;
            case 'twoColumns':
              return <TwoColumns data={el} key={key} />;
          }
        })}
      <div className="top-gradient"></div>
      <Footer entry="aboutUs" slug="" />
    </>
  );
}

export default AboutUs;
