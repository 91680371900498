import { useEffect, useState } from 'react';
import GraphQlHandler from 'api/craft/GraphQlHandler';

import Footer from 'components/Footer';

function OurTeam() {
  const [data, setData] = useState({});

  useEffect(() => {
    GraphQlHandler.query(
      `
      query MyQuery {
        entries(slug: "our-team") {
          ... on ourTeam_ourTeam_Entry {
            id
            title
            header
            subHeader
            mainContent
            ourTeam {
              id
              ... on staff_staffentry_Entry {
                staffName
                position
                staffPhoto {
                  url
                }
              }
            }
          }
        }
      }
	  `
    ).then((res) => {
      const r = res.entries[0];
      setData({
        ...data,
        title: r.title,
        header: r.header,
        subHeader: r.subHeader,
        mainContent: r.mainContent,
        ourTeam: r.ourTeam,
      });
      document.title = r.title;
    });
  }, []);

  return (
    <>
      <div className="top-gradient"></div>
      <div className="container generic-padding-top generic-padding-bottom">
        <h1 className="my-4 subheads">{data.title}</h1>
        <div className="col-md-8">
          <div dangerouslySetInnerHTML={{ __html: data.mainContent }} />
        </div>
        <div className="row our-team">
          {data.ourTeam &&
            data.ourTeam.map((el, idx) => {
              const { staffName, position, staffPhoto } = el;
              const photoUrl = staffPhoto ? staffPhoto[0].url : '';
              return (
                <div className="col-6 col-md-4 col-lg-3 p-2" key={idx}>
                  <div className="card">
                    <img src={photoUrl} alt={staffName} className="img-fluid" />
                    <p className="mt-2 mb-0 text-center fw-bold generic-padding-top generic-padding-bottom">
                      {staffName}
                    </p>
                    <p className="text-center generic-padding-bottom">
                      {position}
                    </p>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      <div className="top-gradient"></div>
      <Footer entry="ourTeam" slug="" />
    </>
  );
}

export default OurTeam;
