import React, { useState } from 'react';

function CaseStudy({ data }) {
  const { boxBackgroundColour } = data;
  const backgroundImage = `url(${data.caseStudyBackgroundImage[0].url})`;
  const handleShowCaseStudy = () => {
    setCaseStudyExpanded(true);
  };
  const handleHideCaseStudy = () => {
    setCaseStudyExpanded(false);
  };
  const [caseStudyExpanded, setCaseStudyExpanded] = useState(false);

  return (
    <>
      <div
        className={`block-case-study${
          !data.onGrayBackground ? ' container-fluid' : ' gray-bg'
        }`}
      >
        <div className={`${data.onGrayBackground ? 'container' : ''}`}>
          <div
            className={`row mb-casestudy ${caseStudyExpanded ? 'expanded' : ''}`}
            style={{
              backgroundImage: backgroundImage,
              backgroundSize: 'contain',
              height: '40rem',
              backgroundPosition: 'right',
            }}
          >
            <div
              className="col-lg-6 p-5 display"
              style={{
                backgroundColor: boxBackgroundColour
                  ? boxBackgroundColour
                  : '#999999',
              }}
            >
              <div className="mx-5 mt-4 mb-1 pb-2">
                <h2>Case Study</h2>
              </div>
              <div
                className="mx-5 mb-2"
                dangerouslySetInnerHTML={{ __html: data.caseStudyText }}
              />
              <div
                className="mx-5 mb-2 case-study-expanded"
                dangerouslySetInnerHTML={{
                  __html: data.caseStudyTextExpanded,
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <div
        className="container-fluid p-5 reverse-display"
        style={{
          backgroundColor: boxBackgroundColour
            ? boxBackgroundColour
            : '#999999',
        }}
      >
        <div className="pt-4 pb-2">
          <h2 className="text-white">Case Study</h2>
        </div>
        <div
          className="mb-2"
          dangerouslySetInnerHTML={{ __html: data.caseStudyText }}
        />
        <div
          className="case-study-expanded"
          dangerouslySetInnerHTML={{
            __html: data.caseStudyTextExpanded,
          }}
        />
      </div>
    </>
  );
}

export default CaseStudy;
