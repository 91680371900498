import Fade from 'react-reveal/Fade';

function WideText({ data }) {
  const { onGrayBackground, wideText } = data;

  return (
    <Fade>
      <div
        className={`block--wide-text${!onGrayBackground ? ' container' : ''}`}
      >
        <div
          className={`container-fluid p-3 ${onGrayBackground ? 'gray-bg' : ''}`}
        >
          <div className={`${onGrayBackground ? 'container' : ''}`}>
            <div className="row">
              <div className="text-center col">
                <h3
                  className="my-3"
                  dangerouslySetInnerHTML={{ __html: wideText }}
                ></h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fade>
  );
}

export default WideText;
