import { useEffect, useState } from 'react';
import GraphQlHandler from 'api/craft/GraphQlHandler';
import Fade from 'react-reveal/Fade';
import Footer from 'components/Footer';
import HeroImage from 'components/HeroImage';
import ListOfTags from 'components/ListOfTags';
import CTAButton from 'components/CTAButton';
import HeaderAndText from 'components/HeaderAndText';
import HorizontalDivider from 'components/HorizontalDivider';
import CaseStudy from 'components/CaseStudy';
import WideText from 'components/WideText';
import Testimonial from 'components/Testimonial';
import TwoColumns from '../components/TwoColumns';
import ImageGallery from '../components/ImageGallery';
import Outcomes from '../components/Outcomes';
import Video from 'components/Video';
import Vimeo from 'components/Vimeo';
import TwoColumnWithImageGallery from 'components/TwoColumnWithImageGallery';
import Sources from 'components/Sources';

function OurProject() {
  const [data, setData] = useState({});

  const slug = window.location.pathname
    .replace(/\/$/, '')
    .substring(
      window.location.pathname.replace(/\/$/, '').lastIndexOf('/') + 1
    );

  useEffect(() => {
    GraphQlHandler.query(
      `
      query MyQuery {
        projectsEntries(slug: "${slug}") {
          ... on projects_projectentry_Entry {
            title
            projectName
            projectHeaderImage {
              url
            }
            projectLogo {
              url
            }
            projectImageTile {
              url
            }
            servicesTags {
              slug
              title
            }
            heroImage {
              url
            }
            contentBlock {
              ... on contentBlock_headerAndText_BlockType {
                text
                cssClassText
                header
                cssClassHeader
                onGrayBackground
                typeHandle
              }
              ... on contentBlock_twoColumns_BlockType {
                leftHeader
                leftText
                cssClassLeftBlock
                rightText
                cssClassRightBlock
                rightHeader
                typeHandle
              }
              ... on contentBlock_sources_BlockType {
                text
                header
                typeHandle
              }
              ... on contentBlock_twocolumnwithimagegallery_BlockType {
                id
                typeHandle
                rightHeader
                rightText
                leftText
                leftHeader
                cssClassLeftBlock
                cssClassRightBlock
                images {
                  ... on images_BlockType {
                    id
                    image {
                      url
                    }
                    cssClass
                  }
                }
              }
              ... on contentBlock_letsChatButton_BlockType {
                pageUrl
                buttonText
                onGrayBackground
                typeHandle
              }
              ... on contentBlock_caseStudy_BlockType {
                caseStudyText
                caseStudyTextExpanded
                ctaButtonLink
                caseStudyBackgroundImage {
                  url
                }
                typeHandle
              }
              ... on contentBlock_logos_BlockType {
                typeHandle
              }
              ... on contentBlock_horizontalDivider_BlockType {
                typeHandle
              }
              ... on contentBlock_wideText_BlockType {
                wideText
                onGrayBackground
                typeHandle
              }
              ... on contentBlock_testimonial_BlockType {
                testimonial
                author
                onGrayBackground
                typeHandle
              }
              ... on contentBlock_wideImage_BlockType {
                wideImage {
                  url
                }
                onGrayBackground
                typeHandle
              }
              ... on contentBlock_video_BlockType {
                video_url
                typeHandle
              }
              ... on contentBlock_vimeo_BlockType {
                id
                vimeo_url
                typeHandle
              }
              ... on contentBlock_imageGallery_BlockType {
                id
                images {
                  ... on images_BlockType {
                    id
                    image {
                      url
                    }
                    cssClass
                  }
                }
                typeHandle
              }
              ... on contentBlock_outcomes_BlockType {
                id
                results {
                  ... on results_BlockType {
                    id
                    result
                    textBelow
                    cssClass
                  }
                }
                typeHandle
              }
            }
          }
        }
      }
      `
    ).then((res) => {
      const r = res.projectsEntries[0];
      setData({
        ...data,
        title: r.title,
        projectLogo: r.projectLogo.length > 0 ? r.projectLogo[0].url : '',
        projectName: r.projectName,
        heroImage: r.projectHeaderImage[0],
        serviceTags: r.servicesTags,
        contentBlock: r.contentBlock,
      });
      document.title = r.title + ' | Ten Four';
    });
  }, []);

  const ctaData = {
    pageUrl: '/our-latest-projects',
    buttonText: 'More projects',
  };

  return (
    <>
      <div className="top-gradient"></div>
      <div className="container page--our-project generic-padding-top generic-padding-bottom">
        <Fade left>
          <div className="container">
            <div className="row generic-padding-top generic-padding-bottom">
              <div className="col-md-6">
                <h1 className="subheads">{data.projectName}</h1>
                <h4 className="generic-padding-top">{data.title}</h4>
              </div>
              <div className="col-md-6 text-end">
                <img className="logo" src={data.projectLogo} alt="" />
              </div>
            </div>
          </div>
        </Fade>
        {data.heroImage && (
          <HeroImage
            data={{
              url: data.heroImage.url,
              onGrayBackground: data.onGrayBackground,
            }}
          />
        )}
        <div className="generic-padding-top generic-padding-bottom">
          {data.serviceTags && <ListOfTags data={data.serviceTags} />}
        </div>
      </div>
      {data.contentBlock &&
        data.contentBlock.map((el, key) => {
          switch (el.typeHandle) {
            case 'headerAndText':
              return <HeaderAndText data={el} key={key} />;
            case 'twoColumns':
              return <TwoColumns data={el} key={key} />;
            case 'horizontalDivider':
              return <HorizontalDivider key={key} />;
            case 'letsChatButton':
              return <CTAButton data={el} key={key} />;
            case 'caseStudy':
              return <CaseStudy data={el} key={key} />;
            case 'wideText':
              return <WideText data={el} key={key} />;
            case 'wideImage':
              return (
                <HeroImage
                  data={{
                    url: el.wideImage[0].url,
                    onGrayBackground: el.onGrayBackground,
                  }}
                  key={key}
                />
              );
            case 'testimonial':
              return <Testimonial data={el} key={key} />;
            case 'imageGallery':
              return <ImageGallery data={el} key={key} />;
            case 'outcomes':
              return <Outcomes data={el} key={key} />;
            case 'video':
              return <Video data={el} key={key} />;
            case 'vimeo':
              return <Vimeo data={el} key={key} />;
            case 'twocolumnwithimagegallery':
              return <TwoColumnWithImageGallery data={el} key={key} />;
            case 'sources':
              return <Sources data={el} key={key} />;
          }
        })}
      <CTAButton data={ctaData} />
      <div className="top-gradient"></div>
      <Footer entry="projectentry" slug="" />
    </>
  );
}

export default OurProject;
