import { useEffect, useState } from 'react';
import GraphQlHandler from 'api/craft/GraphQlHandler';
import Fade from 'react-reveal/Fade';

function OurTeamMember({ id }) {
  const [data, setData] = useState({});

  useEffect(() => {
    GraphQlHandler.query(
      `
		query MyQuery {
			staffEntries(id: "${id}") {
				... on staff_staffentry_Entry {
					staffName
					position
					staffPhoto {
						url
					}
				}
			}
		}		  
		`
    ).then((res) => {
      const r = res.staffEntries[0];
      setData({
        staffName: r.staffName,
        position: r.position,
        url: r.staffPhoto[0].url,
      });
    });
  }, [id]);

  return (
    <>
      <div className="card">
        <img src={data.url} className="img-fluid" alt="" />
        <p className="mt-2 mb-0 text-center fw-bold generic-padding-top generic-padding-bottom">
          {data.staffName}
        </p>
        <p className="text-center generic-padding-bottom">{data.position}</p>
      </div>
    </>
  );
}

export default OurTeamMember;
