import { useEffect, useState } from 'react';
import GraphQlHandler from 'api/craft/GraphQlHandler';
import { useNavigate } from 'react-router-dom';
import Fade from 'react-reveal/Fade';
import Footer from 'components/Footer';
import ListOfTags from 'components/ListOfTags';
import HeroImage from 'components/HeroImage';
import HeaderAndText from 'components/HeaderAndText';
import TwoColumns from 'components/TwoColumns';
import HorizontalDivider from 'components/HorizontalDivider';
import CTAButton from 'components/CTAButton';
import CaseStudy from 'components/CaseStudy';
import WideText from 'components/WideText';
import Testimonial from 'components/Testimonial';
import ThumbnailsList from 'components/ThumbnailsList';
import MoreProjects from 'components/MoreProjects';
import Card from 'components/Card';
import Video from 'components/Video';
import HeaderAndTextWithBackground from 'components/HeaderAndTextWithBackground';
import Sources from 'components/Sources';

function OurService() {
  const slug = window.location.pathname
    .replace(/\/$/, '')
    .substring(
      window.location.pathname.replace(/\/$/, '').lastIndexOf('/') + 1
    );

  const navigate = useNavigate();
  const [data, setData] = useState({});

  useEffect(() => {
    GraphQlHandler.query(
      `
		query MyQuery {
			servicesEntries(slug: "${slug}") {
			  ... on services_serviceentry_Entry {
				id
				title
				serviceHeader
				serviceSubHeader
				heroImage {
				  url
				}
				servicesTags {
				  id
				  title
				}
				contentBlock {
				  ... on contentBlock_headerAndText_BlockType {
					  text
            cssClassText
					  header
            cssClassHeader
					  onGrayBackground
            backgroundImage {
              url
            }
					  typeHandle
				  }
          ... on contentBlock_headerAndTextWithBackground_BlockType {
					  text
            textbackgroundcolor
            cssClassText
					  header
            cssClassHeader
            backgroundImage {
              url
            }
					  typeHandle
				  }
          ... on contentBlock_moreProjects_BlockType {
            header
            projects {
              id
              slug
              title
              ... on projects_projectentry_Entry {
                projectName
                projectImageTile {
                  url
                }
              }
            }
            onGrayBackground
            typeHandle
          }
          ... on contentBlock_twoColumns_BlockType {
            leftHeader
            leftText
            rightHeader
            rightText
            typeHandle
          }
				  ... on contentBlock_quote_BlockType {
					  quoteContent
					  quoteAuthor
					  onGrayBackground
					  typeHandle
				  }
				  ... on contentBlock_letsChatButton_BlockType {
					  pageUrl
					  buttonText
					  onGrayBackground
					  typeHandle
				  }
          ... on contentBlock_sources_BlockType {
            text
            header
            typeHandle
          }
          ... on contentBlock_video_BlockType {
					  video_url
            typeHandle
				  }
				  ... on contentBlock_caseStudy_BlockType {
					  caseStudyText
            boxBackgroundColour
            caseStudyTextExpanded
					  ctaButtonLink
					  caseStudyBackgroundImage {
  					  url
					  }
					  typeHandle
				  }
				  ... on contentBlock_logos_BlockType {
					  logo {
  						url
	  					title
	  				}
		  			onGrayBackground
					  typeHandle
				  }
				  ... on contentBlock_horizontalDivider_BlockType {
					  onGrayBackground
					  typeHandle
					}
					... on contentBlock_wideText_BlockType {
						wideText
						onGrayBackground
						typeHandle
					}
					... on contentBlock_testimonial_BlockType {
						testimonial
						author
						onGrayBackground
						typeHandle
					}
					... on contentBlock_cards_BlockType {
						items {
						... on items_BlockType {
							id
							image {
								url
							}
							header
							cardText
						}
					}
					typeHandle
					}
				}
				footerStaffMembers {
					id
				}
			  }
			}
		  }		  
	  	`
    ).then((res) => {
      if (res.servicesEntries.length == 0) {
        navigate('/');
      }
      const r = res.servicesEntries[0];
      setData({
        ...data,
        title: r.title,
        serviceHeader: r.serviceHeader,
        heroImage: r.heroImage[0],
        serviceSubHeader: r.serviceSubHeader,
        servicesTags: r.servicesTags,
        contentBlock: r.contentBlock,
      });
      document.title = r.title + ' | Ten Four';
    });
  }, []);

  return (
    <>
      <div className="top-gradient"></div>
      <div className="container generic-padding-top generic-padding-bottom">
        <div className="row">
          <Fade left>
            <div className="col-md-6 col-sm-12 row">
              <h1 className="my-4 subheads">{data.serviceHeader}</h1>
              <div className="pb-5">{data.serviceSubHeader}</div>
            </div>
          </Fade>
          {data.heroImage && (
            <HeroImage
              data={{
                url: data.heroImage.url,
                onGrayBackground: data.onGrayBackground,
              }}
            />
          )}
          <div className="generic-padding-top">
            {data.servicesTags && <ListOfTags data={data.servicesTags} />}
          </div>
        </div>
      </div>
      {data.contentBlock &&
        data.contentBlock.map((el, key) => {
          switch (el.typeHandle) {
            case 'headerAndText':
              return <HeaderAndText data={el} key={key} />;
            case 'twoColumns':
              return <TwoColumns data={el} key={key} />;
            case 'horizontalDivider':
              return (
                <HorizontalDivider
                  onGrayBackground={el.onGrayBackground}
                  key={key}
                />
              );
            case 'letsChatButton':
              return <CTAButton data={el} key={key} />;
            case 'caseStudy':
              return <CaseStudy data={el} key={key} />;
            case 'wideText':
              return <WideText data={el} key={key} />;
            case 'testimonial':
              return <Testimonial data={el} key={key} />;
            case 'logos':
              return <ThumbnailsList data={el} key={key} />;
            case 'moreProjects':
              return <MoreProjects data={el} key={key} />;
            case 'cards':
              return <Card data={el} key={key} />;
            case 'video':
              return <Video data={el} key={key} />;
            case 'headerAndTextWithBackground':
              return <HeaderAndTextWithBackground data={el} key={key} />;
            case 'sources':
              return <Sources data={el} key={key} />;
          }
        })}
      <div className="top-gradient"></div>
      <Footer entry="serviceentry" slug={slug} />
    </>
  );
}

export default OurService;
