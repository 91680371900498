import { useEffect, useState } from 'react';
import GraphQlHandler from 'api/craft/GraphQlHandler';
import OurTeamMember from 'components/OurTeamMember';
import MenuFooter from 'components/MenuFooter';
import appSettings from '../AppSettings';

function Footer({ entry, slug }) {
  const [data, setData] = useState({});

  useEffect(() => {
    var query = `
		query MyQuery {
			navigationNodes(navHandle: "footerMenu", level: 1) {
			  title
			  url
			}
			globalSets {
			  ... on global_GlobalSet {
				name
				title
				footerDetails {
				  ... on footerDetails_BlockType {
					header 
					subHeader 
					ctaButtonText 
					ctaButtonLink 
					footerTCText 
					instagramLink 
					facebookLink 
					linkedinLink 
          linkIcon {
            id
            url
          }
					officePhoneNumber
          			contactEmail
          			officeAddress
				  }
				}
			  }
			}`;
    if (entry == '' && slug == '') {
      query += `homePageEntries {
				... on homePage_homePage_Entry {
				  footerStaffMembers {
					  id
				  }
				}
			  }`;
    }
    if (entry == 'serviceentry') {
      query += `
			servicesEntries(slug: "${slug}") {
				... on services_serviceentry_Entry {
					footerStaffMembers {
					  id
					}
				  }
			}`;
    }
    if (entry == 'aboutUs') {
      query += `aboutUsEntries {
				... on aboutUs_aboutUs_Entry {
					footerStaffMembers {
						id
					}
				}
			}`;
    }
    if (entry == 'ourLatestProjects') {
      query += `ourLatestProjectsEntries {
				... on ourLatestProjects_ourLatestProjects_Entry {
					footerStaffMembers {
						id
					}
				}
			}`;
    }
    query += `}`;

    GraphQlHandler.query(query).then((res) => {
      const r = res.globalSets[0].footerDetails[0];
      var staff = [];
      if (entry == '' && slug == '' && res.homePageEntries[0]) {
        staff = res.homePageEntries[0].footerStaffMembers;
      }
      if (entry == 'serviceentry') {
        staff = res.servicesEntries[0].footerStaffMembers;
      }
      if (entry == 'aboutUs') {
        staff = res.aboutUsEntries[0].footerStaffMembers;
      }
      if (entry == 'ourLatestProjects') {
        staff = res.ourLatestProjectsEntries[0].footerStaffMembers;
      }
      setData({
        ...data,
        header: r.header,
        subHeader: r.subHeader,
        ctaButtonText: r.ctaButtonText,
        ctaButtonLink: r.ctaButtonLink,
        footerTCText: r.footerTCText,
        instagramLink: r.instagramLink,
        facebookLink: r.facebookLink,
        linkedinLink: r.linkedinLink,
        officePhoneNumber: r.officePhoneNumber,
        contactEmail: r.contactEmail,
        officeAddress: r.officeAddress,
        staff: staff,
      });
    });
  }, []);

  return (
    <footer>
      <div className="container footer">
        <div className="pt-5 row">
          <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
            <h3 className="footer-header">{data.header}</h3>
            <p className="footer-text">{data.subHeader}</p>
            <a
              href={data.ctaButtonLink}
              className="my-4 btn btn-outline-primary text-align"
            >
              {data.ctaButtonText}
            </a>
          </div>

          <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12">
            <div className="row justify-content-end">
              {data.staff &&
                data.staff?.map((el, idx) => {
                  return (
                    <div className="my-3 col-sm-3 three-col-footer" key={idx}>
                      <OurTeamMember id={`${el.id}`} />
                    </div>
                  );
                })}
            </div>
          </div>
        </div>

        <div className="row generic-padding-bottom">
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <p>
              Say hello{' '}
              <span className="highlighted">
                <a href="tel: +61 7 5578 8211">{data.officePhoneNumber}</a>
                <br />
                <a
                  href="mailto: work-with-us@ten-four.com.au"
                  target="_blank"
                  className="highlighted"
                >
                  {data.contactEmail}
                </a>
                <br />
                {data.officeAddress}
              </span>
            </p>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 d-flex align-items-end justify-content-end">
            <div className="ms-auto">
              <a href={data.instagramLink} target="_blank">
                <img
                  src={appSettings.PUBLIC_URL + '/instagram.svg'}
                  className="m-2"
                />
              </a>
              <a href={data.facebookLink} target="_blank">
                <img
                  src={appSettings.PUBLIC_URL + '/facebook.svg'}
                  className="m-2"
                />
              </a>
              <a href={data.linkedinLink} target="_blank">
                <img
                  src={appSettings.PUBLIC_URL + '/linkedin.svg'}
                  className="m-2"
                />
              </a>
            </div>
          </div>
        </div>
        <hr />
        <div
          className="generic-padding-top xs-pad"
          dangerouslySetInnerHTML={{ __html: data.footerTCText }}
        />

        <MenuFooter className="generic-padding-top" />
      </div>
    </footer>
  );
}

export default Footer;
