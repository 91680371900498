import { useEffect, useState } from 'react';
import GraphQlHandler from 'api/craft/GraphQlHandler';
import ListOfTags from 'components/ListOfTags';
import Footer from 'components/Footer';
import ListOfContributors from '../components/ListOfContributors';
import { Link } from 'react-router-dom';

function Article() {
  const [data, setData] = useState({});

  const slug = window.location.pathname
    .replace(/\/$/, '')
    .substring(
      window.location.pathname.replace(/\/$/, '').lastIndexOf('/') + 1
    );

  useEffect(() => {
    GraphQlHandler.query(
      `
      query MyQuery {
        latestBlogs: blogEntries(limit: 5, offset: 0) {
          ... on blog_blogentry_Entry {
            postDate
            title
            slug
          }
        }
        blogEntries(
          slug: "${slug}"
        ) {
          ... on blog_blogentry_Entry {
            postDate
            title
            slug
            blogAuthor {
              title
              ... on staff_staffentry_Entry {
                id
                position
                staffPhoto {
                  url
                }
              }
            }
            blogShortDescription
            blogFullDescription
            heroImage {
              url
            }
            servicesTags {
              id
              title
            }
          }
        }
        latestArticlesEntries {
          ... on latestArticles_latestArticles_Entry {
            title
            ourTeamOfContributors {
              ... on staff_staffentry_Entry {
                id
                staffName
                position
                staffPhoto {
                  url
                }
              }
            }
          }
        }
      }
      
	  `
    ).then((res) => {
      const r = res.blogEntries[0];
      setData({
        ...data,
        title: r.title,
        heroImage: r.heroImage,
        postDate: r.postDate,
        serviceTags: r.servicesTags,
        blogAuthor: r.blogAuthor,
        blogFullDescription: r.blogFullDescription,
        ourTeamOfContributors:
          res.latestArticlesEntries[0].ourTeamOfContributors,
        latestBlogs: res.latestBlogs,
      });
      document.title = 'Blog | Ten Four - ' + r.title;
    });
  }, []);

  const heroImage =
    data.heroImage && data.heroImage.length > 0 ? (
      <img
        src={data.heroImage[0].url}
        className="img-fluid mb-4"
        alt="Your image"
      />
    ) : (
      <></>
    );

  const articleDate = new Date(data.postDate).toLocaleDateString('en-AU', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  });

  return (
    <>
      <div className="top-gradient"></div>
      <div className="container page--article generic-padding-top generic-padding-bottom">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12">
            <h1 className="mt-4 subheads">{data.title}</h1>
            <div className="text-muted mb-4">
              {articleDate} |&nbsp;
              {data.serviceTags && (
                <ListOfTags data={data.serviceTags} asBlock="false" />
              )}
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-12 col-md-9 border-right">
            {heroImage}
            {data.blogAuthor && data.blogAuthor.length > 0 && (
              <>
                <hr className="hr-article" />
                <div className="pt-3">
                  <div className="d-flex align-items-center">
                    <img
                      src={data.blogAuthor[0].staffPhoto[0].url}
                      className="mb-4 article-img"
                      alt={data.blogAuthor[0].title}
                    />
                    <div className="p-1">
                      <div className="my-0">
                        <strong>Written by {data.blogAuthor[0].title}</strong>
                      </div>
                      <div className="my-0">{data.blogAuthor[0].position}</div>
                    </div>
                  </div>
                </div>
                <br />
              </>
            )}

            <div
              className="article-text"
              dangerouslySetInnerHTML={{ __html: data.blogFullDescription }}
            />
          </div>
          <hr className="h-horizontal" />
          <div className="col-12 col-md-3 p-1 no-strech">
            {data.latestBlogs && (
              <>
                <h5 className="">Recent posts</h5>
                <ul className="recent-posts list-unstyled mb-5">
                  {data.latestBlogs.map((el, key) => {
                    const handleClick = () => {
                      window.location.href = `/articles/${el.slug}`;
                    };

                    return (
                      <li className="my-2">
                        <Link
                          to={`/articles/${el.slug}`}
                          className="small"
                          key={key}
                          onClick={handleClick}
                        >
                          {el.title}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </>
            )}

            <ListOfContributors data={data.ourTeamOfContributors} />
          </div>
        </div>
      </div>
      <div className="top-gradient"></div>
      <Footer entry="projectentry" slug="" />
    </>
  );
}

export default Article;
