import { useEffect, useState } from 'react';
import GraphQlHandler from 'api/craft/GraphQlHandler';
import Footer from 'components/Footer';
import ProjectTile from '../components/ProjectTile';

function OurLatestProjects() {
  const [data, setData] = useState({});

  useEffect(() => {
    GraphQlHandler.query(
      `
		query MyQuery {
			ourLatestProjectsEntries {
			  ... on ourLatestProjects_ourLatestProjects_Entry {
				id
				title
				subHeader
				listOfLatestProjects {
				  id
				  slug
				  title
				  ... on projects_projectentry_Entry {
					id
					projectName
					projectImageTile {
					  url
					}
				  }
				}
			  }
			}
		  }		  
	  `
    ).then((res) => {
      const r = res.ourLatestProjectsEntries[0];
      setData({
        ...data,
        title: r.title,
        subHeader: r.subHeader,
        latestProjects: r.listOfLatestProjects,
      });
      document.title = 'Projects | Ten Four - ' + r.title;
    });
  }, []);

  return (
    <>
      <div className="top-gradient"></div>
      <div className="container page--our-latest-projects generic-padding-top generic-padding-bottom">
        <div className="col-6">
          <h1 className="my-4 subheads">{data.title}</h1>
          <p>{data.subHeader}</p>
          <div dangerouslySetInnerHTML={{ __html: data.mainContent }} />
        </div>

        <div className="row generic-padding-top generic-padding-bottom">
          {data.latestProjects &&
            data.latestProjects.map((el, idx) => {
              return (
                <ProjectTile
                  data={{
                    imageUrl: el.projectImageTile[0].url,
                    title: el.title,
                    slug: el.slug,
                    subTitle: el.projectName,
                  }}
                  key={idx}
                />
              );
            })}
        </div>
      </div>
      <div className="top-gradient"></div>
      <Footer entry="ourLatestProjects" slug="" />
    </>
  );
}

export default OurLatestProjects;
