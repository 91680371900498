import Fade from 'react-reveal/Fade';

function TwoColumns({ data }) {
  const {
    leftHeader,
    cssClassLeftBlock,
    leftText,
    rightHeader,
    cssClassRightBlock,
    rightText,
    onGrayBackground,
  } = data;
  return (
    <Fade>
      <div
        className={`block--two-columns${
          !onGrayBackground ? ' container' : ' gray-bg'
        }`}
      >
        <div className={`${onGrayBackground ? 'container' : ''}`}>
          <div className="row generic-padding-bottom">
            <div className="col-md-6 p-4">
              <h2 className={`${cssClassLeftBlock ? cssClassLeftBlock : ''}`}>
                {leftHeader}
              </h2>
              <div
                className={`${cssClassLeftBlock ? cssClassLeftBlock : 'generic-padding-top'}`}
                dangerouslySetInnerHTML={{ __html: leftText }}
              />
            </div>
            <div className="col-md-6 p-4">
              <h2 className={`${cssClassRightBlock ? cssClassRightBlock : ''}`}>
                {rightHeader}
              </h2>
              <div
                className={`${cssClassRightBlock ? cssClassRightBlock : 'generic-padding-top'}`}
                dangerouslySetInnerHTML={{ __html: rightText }}
              />
            </div>
          </div>
        </div>
      </div>
    </Fade>
  );
}

export default TwoColumns;
