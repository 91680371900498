import CTAButton from 'components/CTAButton';
import ProjectTile from 'components/ProjectTile';
import Fade from 'react-reveal/Fade';

function MoreProjects({ data }) {
  const { header, onGrayBackground, projects } = data;

  return (
    <Fade>
      <div
        className={`block--more-projects py-4${
          !onGrayBackground ? ' container' : ' gray-bg'
        }`}
      >
        <div
          className={`${onGrayBackground ? 'container generic-padding-top' : ''}`}
        >
          <div className="row my-4 row justify-content-center">
            <h2>{header}</h2>
            {projects &&
              projects.map((el, key) => {
                return (
                  <ProjectTile
                    data={{
                      slug: el.slug,
                      imageUrl:
                        el.projectImageTile && el.projectImageTile.length > 0
                          ? el.projectImageTile[0].url
                          : null,
                      title: el.title,
                      subTitle: el.projectName,
                    }}
                    key={key}
                  />
                );
              })}
            <CTAButton
              data={{
                buttonText: 'More projects',
                pageUrl: '/our-latest-projects',
                onGrayBackground: false,
                typeHandle: 'letsChatButton',
              }}
            />
          </div>
        </div>
      </div>
    </Fade>
  );
}

export default MoreProjects;
