import { useEffect, useState } from 'react';
import GraphQlHandler from 'api/craft/GraphQlHandler';
import Fade from 'react-reveal/Fade';
import Slide from 'react-reveal/Slide';
import Footer from 'components/Footer';
import ThumbnailsList from 'components/ThumbnailsList';
import WideText from 'components/WideText';
import ListOfTags from 'components/ListOfTags';
import LatestProjects from 'components/LatestProjects';

export default () => {
  const [data, setData] = useState({});

  useEffect(() => {
    GraphQlHandler.query(
      `
      query MyQuery {
        homePageEntries {
          ... on homePage_homePage_Entry {
            title
            heroImage {
              url
            }
            servicesTags {
				      id
				      title
				    }
            serviceHeader
            mainContent
            relatedProjectsHeader
            hyperlink {
              url
            }
            relatedProjects {
              id
              url
              slug
              ... on projects_projectentry_Entry {
                id
                title
                projectName
                projectHomepageHeroImage {
                  url
                }
              }
            }
            homepageStatisticsHeader
            homepageStatisticsLeftSideLogo {
              url
            }
            homepageStatisticsRightSideLogo {
              url
            }
            statsPercentages {
              ... on statsPercentages_BlockType {
                numbers
                subtextForNumbers
              }
            }
            testimonialText
            testimonialAuthor

            contentBlock {
              ... on contentBlock_wideText_BlockType {
                wideText
                onGrayBackground
                typeHandle
              }
              ... on contentBlock_logos_BlockType {
                logo {
                  url
                  title
                }
                onGrayBackground
                typeHandle
              }
            }
          }
        }
      }
    `
    ).then((res) => {
      const r = res.homePageEntries[0];
      setData({
        ...data,
        heroImage: r.heroImage,
        statisticsHeader: r.homepageStatisticsHeader,
        statisticsLeftSideLogo: r.homepageStatisticsLeftSideLogo,
        statisticsRightSideLogo: r.homepageStatisticsRightSideLogo,
        statsPercentages: r.statsPercentages,
        statsTestimonialText: r.testimonialText,
        statsTestimonialAuthor: r.testimonialAuthor,
        serviceHeader: r.serviceHeader,
        servicesTags: r.servicesTags,
        mainContent: r.mainContent,
        relatedProjectsHeader: r.relatedProjectsHeader,
        hyperlink: r.hyperlink,
        relatedProjects: r.relatedProjects,
        contentBlock: r.contentBlock,
      });
      document.title = r.title;
    });
  }, []);
  {
    data.hyperlink &&
      data.hyperlink.map((hyperlink, key) => {
        data.hyperlink = hyperlink.url;
      });
  }
  return (
    <div className="page--home">
      <div className="top-gradient"></div>
      {data.heroImage && data.heroImage.length > 0 && (
        <div>
          <div className="container-fluid">
            <Fade>
              <div
                className="hero-image img-fluid"
                style={{ backgroundImage: `url(${data.heroImage[0].url})` }}
              >
                <Fade left>
                  <div className="banner-text">
                    <h1 className="pt-5 pb-3 generic-gradient subheads">
                      {data.serviceHeader}
                    </h1>
                    <img src={data.hyperlink[0].url}></img>

                    <div
                      className="homebannercontent"
                      dangerouslySetInnerHTML={{ __html: data.mainContent }}
                    />
                  </div>
                </Fade>
              </div>

              {data.servicesTags && (
                <ListOfTags
                  className="tags"
                  data={data.servicesTags}
                  centered="true"
                />
              )}
            </Fade>
          </div>
        </div>
      )}

      <LatestProjects
        data={{
          header: data.relatedProjectsHeader,
          projects: data.relatedProjects,
          slug: data.relatedProjects,
          hyperlinkUrl: data.hyperlink,
        }}
      />

      <div className="container block--commitment">
        <div className="row my-4">
          <div className="col-md-6 p-4">
            <h2 className="mt-4">{data.statisticsHeader}</h2>
            {data.statisticsLeftSideLogo &&
              data.statisticsLeftSideLogo.length > 0 &&
              data.statisticsLeftSideLogo[0] && (
                <Fade>
                  <figure className="commitment-onecol">
                    <img src={data.statisticsLeftSideLogo[0].url} alt="" />
                  </figure>
                </Fade>
              )}
          </div>
          <Fade>
            <div className="col-md-6 p-4 row text-center m-view">
              {data.statisticsRightSideLogo &&
                data.statisticsRightSideLogo.length > 0 &&
                data.statisticsRightSideLogo[0] && (
                  <figure className="houseofwaris">
                    <img src={data.statisticsRightSideLogo[0].url} alt="" />
                  </figure>
                )}
              {data.statsPercentages &&
                data.statsPercentages.map((el, key) => {
                  return (
                    <div className="col text-center" key={key}>
                      <div className="stats-numbers">{el.numbers}%</div>
                      <div className="small">{el.subtextForNumbers}</div>
                    </div>
                  );
                })}
              <div className="block--testimonial">
                <div
                  className="mt-4 text-center testtimonial-text"
                  dangerouslySetInnerHTML={{
                    __html: data.statsTestimonialText,
                  }}
                />
                <div className="mt-4 text-center testtimonial-text">
                  {data.statsTestimonialAuthor}
                </div>
              </div>
            </div>
          </Fade>
        </div>
      </div>

      {data.contentBlock &&
        data.contentBlock.map((el, key) => {
          switch (el.typeHandle) {
            case 'logos':
              return <ThumbnailsList data={el} key={key} />;
            case 'wideText':
              return <WideText data={el} key={key} />;
          }
        })}
      <div className="top-gradient"></div>
      <Footer entry="" slug="" />
    </div>
  );
};
