import { useEffect, useState } from 'react';
import GraphQlHandler from 'api/craft/GraphQlHandler';
import MenuNavigation from '../components/MenuNavigation';
import Footer from 'components/Footer';
import HeroImage from 'components/HeroImage';
import WideText from 'components/WideText';
import ThumbnailsList from 'components/ThumbnailsList';
import JotFormEmbed from 'components/JotFormEmbed';

function GetInTouch() {
  const [data, setData] = useState({});

  useEffect(() => {
    GraphQlHandler.query(
      `
		query MyQuery {
			entries(slug: "get-in-touch") {
			  ... on getInTouch_getInTouch_Entry {
				id
				title
				header
				subHeader
				mainContent
				contentBlock {
					... on contentBlock_wideImage_BlockType {
						wideImage {
							url
						}
						typeHandle
					  }
					... on contentBlock_headerAndText_BlockType {
					  text
					  header
					  onGrayBackground
					  typeHandle
					}
					... on contentBlock_letsChatButton_BlockType {
					  pageUrl
					  buttonText
					  onGrayBackground
					  typeHandle
					}
					... on contentBlock_caseStudy_BlockType {
					  caseStudyText
					  ctaButtonLink
					  caseStudyBackgroundImage {
						url
					  }
					  typeHandle
					}
					... on contentBlock_logos_BlockType {
					  typeHandle
					  logo {
						  url
						  title
					  }
					}
					... on contentBlock_horizontalDivider_BlockType {
					  typeHandle
					}
					... on contentBlock_wideText_BlockType {
					  wideText
					  onGrayBackground
					  typeHandle
					}
					... on contentBlock_testimonial_BlockType {
					  testimonial
					  author
					  onGrayBackground
					  typeHandle
					}
				  }
			  }
			}
		  }		  
	  `
    ).then((res) => {
      const r = res.entries[0];
      setData({
        ...data,
        title: r.title,
        header: r.header,
        subHeader: r.subHeader,
        serviceHeader: r.serviceHeader,
        mainContent: r.mainContent,
        contentBlock: r.contentBlock,
      });
      document.title = r.title;
    });
  }, []);

  return (
    <>
      <div className="top-gradient"></div>
      <div className="container generic-padding-top generic-padding-bottom">
        <div>
          <h1 className="mt-4 col-md-5 subheads">{data.header}</h1>
        </div>
        <div
          className="col-md-6 generic-padding-bottom"
          dangerouslySetInnerHTML={{ __html: data.mainContent }}
        />
      </div>

      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6 mb-2">
            <JotFormEmbed />
          </div>
          <div className="col-12 col-md-6 p-4">
            <div className="mb-5">
              {data.contentBlock &&
                data.contentBlock.map((el, key) => {
                  switch (el.typeHandle) {
                    case 'wideImage':
                      return (
                        <HeroImage
                          data={{
                            url: el.wideImage[0].url,
                            onGrayBackground: el.onGrayBackground,
                          }}
                          key={key}
                        />
                      );
                    case 'wideText':
                      return <WideText data={el} key={key} />;
                    case 'logos':
                      return <ThumbnailsList data={el} key={key} />;
                  }
                })}
            </div>
          </div>
        </div>
      </div>

      <div className="top-gradient"></div>
      <Footer entry="getInTouch" slug="" />
    </>
  );
}

export default GetInTouch;
