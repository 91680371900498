import Zoom from 'react-reveal/Zoom';

function CTAButton({ data }) {
  const buttonText = data.buttonText ? data.buttonText : "Let's have a chat";

  return (
    <Zoom>
      <div
        className={`block-cta-button${data.onGrayBackground ? ' gray-bg' : ''}`}
      >
        <div className={`${data.onGrayBackground ? 'container' : ''}`}>
          <div className="g-flex justify-content-center button-pads">
            {' '}
            {/* Remove col-md-12 class */}
            <a href={data.pageUrl} className="btn btn-outline-primary">
              {buttonText}
            </a>
          </div>
        </div>
      </div>
    </Zoom>
  );
}

export default CTAButton;
