import Pulse from 'react-reveal/Pulse';

function ImageGallery({ data }) {
  const { items } = data;
  return (
    <Pulse>
      <div className="block--card">
        <div className="container">
          <div className="row">
            {items &&
              items.map((el, key) => {
                return (
                  <>
                    <div
                      className="col-12 col-md-6 col-lg-4 card p-4"
                      key={key}
                    >
                      {el.image.length > 0 && (
                        <img
                          src={el.image[0].url}
                          alt=""
                          className="grid-image generic-padding-bottom"
                        />
                      )}
                      <h3 className="generic-padding-top generic-padding-bottom">
                        {el.header}
                      </h3>
                      <div
                        dangerouslySetInnerHTML={{ __html: el.cardText }}
                        className="generic-padding-top generic-padding-bottom"
                      />
                    </div>
                  </>
                );
              })}
          </div>
        </div>
      </div>
    </Pulse>
  );
}

export default ImageGallery;
