import { BrowserRouter } from 'react-router-dom';
import App from 'App';
import '../src/main.scss';
import ScrollToTop from 'react-scroll-to-top';
import { createRoot } from 'react-dom/client';

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript

root.render(
  <BrowserRouter>
    <App />
    <ScrollToTop viewBox="0 0 24 24" component={<>&#8593;</>} />
  </BrowserRouter>
);
