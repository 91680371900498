const appSettings = {
  graphQlEndpoint:
    process.env.REACT_APP_ENV === undefined ||
    !(process.env.REACT_APP_ENV === 'DEV')
      ? '/graphql'
      : process.env.REACT_APP_GRAPHQL_ENDPOINT,
  PUBLIC_URL:
    process.env.REACT_APP_ENV === undefined ||
    !(process.env.REACT_APP_ENV === 'DEV')
      ? '/build/'
      : process.env.PUBLIC_URL,
};

export default appSettings;
