import { useEffect, useState } from 'react';
import GraphQlHandler from 'api/craft/GraphQlHandler';
import NavBar from './NavBar';
import appSettings from '../AppSettings';
function MenuNavigation() {
  const [data, setData] = useState({});

  useEffect(() => {
    GraphQlHandler.query(
      `
		query MyQuery {
		navigationNodes(navHandle: "mainMenu", level: 1) {
			title
			url
			classes
			children {
				title
				url
				}
			}
		}
		`
    ).then((res) => {
      setData({ ...data, menu: res.navigationNodes });
    });
  }, []);

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-dark top-nav">
        <div className="container">
          <a href="/" className="navbar-brand">
            <img src={appSettings.PUBLIC_URL + '/logo.svg'} width="140px" />
          </a>
          <button
            type="button"
            className="navbar-toggler"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          {data && data?.menu && <NavBar data={data.menu} />}
        </div>
      </nav>
    </>
  );
}

export default MenuNavigation;
