import Fade from 'react-reveal/Fade';
import { useLocation } from 'react-router-dom';

function ListOfTags({ data, asBlock = 'true', centered = 'true' }) {
  const location = useLocation();
  const isHomePage = location.pathname === '/';
  const mappedData = data.map((item, index) => (
    <span key={index}>
      {item.title}
      {index !== data.length - 1 &&
        (asBlock == 'true' ? <span className="separator">|</span> : <>, </>)}
    </span>
  ));

  return asBlock == 'true' ? (
    <Fade>
      <div
        className={`block--list-of-tags d-flex pb-4${isHomePage ? ' gray-bg' : ''}${centered ? ' justify-content-center' : ''}`}
      >
        <div
          className={`flex-wrap d-flex${
            centered == 'true' ? ' justify-content-center' : ''
          }`}
        >
          {mappedData}
        </div>
      </div>
    </Fade>
  ) : (
    <>{mappedData}</>
  );
}

export default ListOfTags;
