import Fade from 'react-reveal/Fade';
function ThumbnailsList({ data }) {
  const { logo, onGrayBackground } = data;

  return (
    <div
      className={`block--thumbnails-list${
        !onGrayBackground ? ' container' : ' gray-bg pb-5'
      }`}
    >
      <div
        className={`d-flex pt-4 pb-4 x justify-content-between${
          data.onGrayBackground ? ' gray-bg' : ''
        }`}
      >
        <Fade>
          <div className={`container`}>
            <div className="row">
              {logo &&
                logo.map((image, key) => {
                  return (
                    <div
                      className="col g-flex generic-padding-bottom justify-content-center"
                      key={key}
                    >
                      <img
                        src={image.url}
                        alt={image.title}
                        className="img-thumbnail"
                      />
                    </div>
                  );
                })}
            </div>
          </div>
        </Fade>
      </div>
    </div>
  );
}

export default ThumbnailsList;
